<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-model="query.companyId" filterable clearable
					placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 120px" v-if="isAdmin || isagent" v-model="query.businessType"
					filterable clearable placeholder="企业类型" class="handle-select mr10">
					<el-option v-for="item in businessTypeList" :key="item" :label="item" :value="item"></el-option>
				</el-select>
				<el-select size="small" style="width: 120px" v-if="isAdmin || isagent" v-model="query.enable" filterable
					clearable placeholder="是否合作" class="handle-select mr10">
					<el-option label="是" value="true"></el-option>
					<el-option label="否" value="false"></el-option>
				</el-select>

				<el-input size="small" v-model="query.companyName" clearable placeholder="请输入公司名称" class="mr10"
					style="width: 180px;" v-if="query.enable == 'false'"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2" @click="handleExport"
					v-if="isAdmin|| isagent">导出</el-button>
				<el-button size="small" v-if="canAddCompany" type="primary" :class="[theme]" class="handle-add mr100"
					@click="showForm">添加企业</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="name" width="340" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="code" label="企业代码" align="center"></el-table-column>
				<el-table-column prop="businessType" label="企业类型" align="center"></el-table-column>
				<el-table-column prop="showProtectionLevel" label="业务类型" align="center"></el-table-column>
				<el-table-column prop="ownerName" label="企业联系人" align="center"></el-table-column>
				<el-table-column prop="ownerPhone" label="负责人联系电话" align="center"></el-table-column>
				<el-table-column prop="enable" label="是否合作" align="center" v-if="isAdmin || isagent">
					<template #default="scope">
						<el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949"
							@change="getswitchenable(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
						<el-button size="small" :disabled="canRemoveCompany" type="text" icon="el-icon-delete"
							style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row.id)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="添加企业" v-model="addVisible" width="1000px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="企业名称" prop="name"><el-input size="small" v-model="form.name"
						:disabled="canRemoveCompany"></el-input></el-form-item>
				<el-form-item label="企业代码" prop="code"><el-input size="small" v-model="form.code"
						:disabled="canRemoveCompany"></el-input></el-form-item>

				<div class="content_box">
					<div class="content_top">
						<!-- 传入营业执照 -->
						<el-form-item label="营业执照" prop="businessLicense">
							<!-- <el-input size="small" v-model="form.businessLicense" v-if="false"></el-input> -->
							<el-upload :disabled="canRemoveCompany" :action="domain" :limit="1" list-type="picture-card"
								:file-list="fileList1" :auto-upload="false" ref="upload1"
								accept="image/png,image/jpg,image/jpeg" :on-change="getbusinessLicense" :data="token1"
								:http-request="httprequest" :class="{ hide: hideUpload1 }"
								:on-preview="handlePictureCardPreview1" :on-remove="handleRemove1"
								:before-remove="beformRemove1">
								<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
							</el-upload>

							<el-dialog v-model="addVisible1"><img :src="dialogImageUrl1"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
						<div>
							<!-- 传入身份证正面 -->
							<el-form-item label="身份证正面" prop="idcardFront">
								<!-- <el-input size="small" v-model="form.IDCardFront" v-if="false"></el-input> -->
								<el-upload :disabled="canRemoveCompany" :action="idcardFront" :limit="1"
									list-type="picture-card" :file-list="fileList2" :auto-upload="false" ref="upload2"
									accept="image/png,image/jpg,image/jpeg" :on-change="getIDCardFront"
									:data="idcardFront" :http-request="httprequest" :class="{ hide: hideUpload2 }"
									:before-remove="beformRemove2" :on-preview="handlePictureCardPreview2"
									:on-remove="handleRemove2">
									<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
								</el-upload>
								<el-dialog v-model="addVisible2"><img :src="dialogImageUrl2"
										style="width: 100%;" /></el-dialog>
							</el-form-item>
						</div>
						<!-- 传入身份证反面 -->
						<el-form-item label="身份证反面" prop="idcardReverse">
							<el-input size="small" v-model="form.idcardReverse" v-if="false"></el-input>
							<el-upload :disabled="canRemoveCompany" :action="idcardReverse" :limit="1"
								list-type="picture-card" :file-list="fileList3" :auto-upload="false" ref="upload3"
								accept="image/png,image/jpg,image/jpeg" :on-change="getIDCardReverse"
								:data="idcardReverse" :http-request="httprequest" :class="{ hide: hideUpload3 }"
								:before-remove="beformRemove3" :on-preview="handlePictureCardPreview3"
								:on-remove="handleRemove3">
								<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible3"><img :src="dialogImageUrl3"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
					</div>

					<div class="content_middle">
						<!-- 法人免冠照一张 -->
						<el-form-item label="法人免冠照" prop="legalPersonPhotos">
							<el-input size="small" v-model="form.legalPersonPhotos" v-if="false"></el-input>
							<el-upload :disabled="canRemoveCompany" :action="legalPersonPhotos" :limit="1"
								list-type="picture-card" :file-list="fileList4" :auto-upload="false" ref="upload4"
								accept="image/png,image/jpg,image/jpeg" :on-change="getlegalPersonPhotos"
								:data="legalPersonPhotos" :http-request="httprequest" :class="{ hide: hideUpload4 }"
								:before-remove="beformRemove4" :on-preview="handlePictureCardPreview4"
								:on-remove="handleRemove4">
								<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible4"><img :src="dialogImageUrl4"
									style="width: 100%;" /></el-dialog>
						</el-form-item>

						<div>
							<!-- 话术模板 -->
							<el-form-item label="话术模板(1)" prop="template1">
								<el-upload :disabled="canRemoveCompany" :action="template1" list-type="picture-card"
									:file-list="fileList5" :auto-upload="false" ref="upload5"
									accept="image/png,image/jpg,image/jpeg" :on-change="gettemplate1" :data="template1"
									:http-request="httprequest" :limit="1" :class="{ hide: hideUpload5 }"
									:before-remove="beformRemove5" :on-preview="handlePictureCardPreview5"
									:on-remove="handleRemove5">
									<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
								</el-upload>
								<el-dialog v-model="addVisible5"><img :src="dialogImageUrl5"
										style="width: 100%;" /></el-dialog>
							</el-form-item>
						</div>
						<!-- 话术模板 -->
						<el-form-item label="话术模板(2)" prop="template2">
							<el-upload :disabled="canRemoveCompany" :action="template2" list-type="picture-card"
								:file-list="fileList6" :auto-upload="false" ref="upload6"
								accept="image/png,image/jpg,image/jpeg" :on-change="gettemplate2" :data="templat2"
								:http-request="httprequest" :limit="1" :class="{ hide: hideUpload6 }"
								:before-remove="beformRemove6" :on-preview="handlePictureCardPreview6"
								:on-remove="handleRemove6">
								<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible6"><img :src="dialogImageUrl6"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
					</div>
					<div class="content_bottom">
						<!-- 话术模板 -->
						<el-form-item label="话术模板(3)" prop="template3">
							<el-upload :disabled="canRemoveCompany" :action="template3" list-type="picture-card"
								:file-list="fileList7" :auto-upload="false" ref="upload7"
								accept="image/png,image/jpg,image/jpeg" :on-change="gettemplate3" :data="template3"
								:http-request="httprequest" :limit="1" :class="{ hide: hideUpload7 }"
								:before-remove="beformRemove7" :on-preview="handlePictureCardPreview7"
								:on-remove="handleRemove7">
								<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
							</el-upload>
							<el-dialog v-model="addVisible7"><img :src="dialogImageUrl7"
									style="width: 100%;" /></el-dialog>
						</el-form-item>
						<div>
							<!-- 话术模板 -->
							<el-form-item label="话术模板(4)" prop="template4">
								<el-upload :disabled="canRemoveCompany" :action="template4" list-type="picture-card"
									:file-list="fileList8" :auto-upload="false" ref="upload8"
									accept="image/png,image/jpg,image/jpeg" :on-change="gettemplate4" :data="template4"
									:http-request="httprequest" :limit="1" :class="{ hide: hideUpload8 }"
									:before-remove="beformRemove8" :on-preview="handlePictureCardPreview8"
									:on-remove="handleRemove8">
									<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
								</el-upload>
								<el-dialog v-model="addVisible8"><img :src="dialogImageUrl8"
										style="width: 100%;" /></el-dialog>
							</el-form-item>
						</div>
						<!-- 话术模板 -->
						<el-form-item label="告知书" prop="template5">
							<el-upload :disabled="canRemoveCompany" :action="template5" list-type="picture-card"
								:file-list="fileList9" :auto-upload="false" ref="upload9" accept="video/*"
								:on-change="gettemplate5" :data="template5" :http-request="httprequest" :limit="1"
								:class="{ hide: hideUpload9 }" :before-remove="beformRemove9"
								:on-preview="handlePictureCardPreview9" :on-remove="handleRemove9">
								<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
							</el-upload>

							<el-dialog v-model="addVisible9" @close="closeDialogdialogImageUrl9"><video
									v-if="dialogImageUrl9" :src="dialogImageUrl9" controls></video></el-dialog>
						</el-form-item>
					</div>
				</div>

				<el-form-item label="代理商" v-if="isAdmin" prop="agentId">
					<el-select size="small" v-model="form.agentId" filterable clearable placeholder="代理商"
						class="handle-select mr10" style="width: 100%;">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="子代理" prop="subAgentId" v-if="isSubAgent">
					<el-select size="small" v-model="form.subAgentId" @change="getfetchDefaultCompanies" filterable
						clearable placeholder="子代理" class="handle-select mr10" style="width: 100%;">
						<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业联系人" prop="ownerName"><el-input size="small"
						v-model="form.ownerName"></el-input></el-form-item>
				<el-form-item label="联系电话" prop="ownerPhone"><el-input size="small"
						v-model="form.ownerPhone"></el-input></el-form-item>
				<el-form-item label="企业类型" prop="businessType">
					<el-select size="small" style="width: 100%;" v-model="form.businessType" filterable clearable
						placeholder="企业类型" class="handle-select mr10" :disabled="protectionLevelTrue">
						<el-option v-for="item in businessTypeList" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="业务类型" prop="protectionLevel">
					<el-cascader v-model="form.protectionLevel" :options="protectionLevel" @change="handleChange"
						size="small" :disabled="protectionLevelTrue"></el-cascader>
					<a class="ywlx" href="http://images.10000.work/888.jpg" target="_blank"
						style="background-color: none;list-style: none;color: #000000;margin-left: 12px;">
						点击查看规则>>
					</a>
				</el-form-item>
				<!-- <el-form-item label="高频规则" prop="frequency">
					<el-cascader v-model="form.frequency" :options="frequencyList" @change="frequencyhandleChange" size="small" :disabled="protectionLevelTrue"></el-cascader>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span style="font-size: 12px;color: #878A93;">该企业内所有被叫号码高频规则</span>
				</el-form-item>
 -->
				<el-form-item label="部门开启" prop="department">
					<el-switch v-model="form.department" active-color="#13ce66" inactive-color="#ff4949"
						@change="getswitch3"></el-switch>
					<span style="color: #ff0000;margin-left: 20px;font-size: 12px;">*
						开启后给该企业下添加部门，部门名称为销售一部（后期可编辑修改）。</span>
				</el-form-item>
				<el-form-item label="号码保护" prop="desensitization">
					<el-switch v-model="form.desensitization" active-color="#13ce66" inactive-color="#ff4949"
						@change="getswitch4"></el-switch>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span style="font-size: 12px;color: #878A93;">开启号码保护功能，被叫号码中间四位数加密，所有通话记录和通话详情位置生效。</span>
				</el-form-item>
				<el-form-item :label="repeatText" prop="repeat">
					<el-switch v-model="form.repeat" active-color="#13ce66" inactive-color="#ff4949"
						@change="getswitch"></el-switch>
					<span style="color: #ff0000;margin-left: 20px;font-size: 12px;">
						* 开启去重功能，同一企业下重复号码自动过滤，对系统所有导入号码的位置生效，包括PC端导入、APP端导入、文字识别导入。
					</span>
				</el-form-item>
				<el-form-item label="使用小程序" prop="applet">
					<el-switch v-model="form.applet" active-color="#13ce66" inactive-color="#ff4949"
						@change="getappletswitch"></el-switch>
					<span style="color: #ff0000;margin-left: 20px;font-size: 12px;">* 默认是开启状态，可使用小程序，关闭就无法使用小程序 。</span>
				</el-form-item>
				<el-form-item label="号码导出" prop="export">
					<el-switch v-model="form.export" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span style="font-size: 12px;color: #878A93;">开启或关闭APP端和PC端话单导出功能。</span>
				</el-form-item>
				<el-form-item label="高频范围" prop="highFrequency"
					style="background: #E9EAEF;margin-bottom: 0;padding-top: 11px;" v-if="repeatCallgew">
					<el-switch v-model="form.highFrequency" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span style="font-size: 12px;color: #878A93;"
						v-if="form.highFrequency">{{ webInfo.webName }}所有外呼生效</span>
					<span style="font-size: 12px;color: #878A93;" v-else>企业内所有外呼生效</span>
				</el-form-item>
				<el-form-item label="重复拨打" prop="repeatCall" v-if="repeatCallgew"
					style="background: #E9EAEF;padding-bottom: 11px;padding-top: 22px;">
					<el-select size="small" :disabled="canRemoveCompany" v-model="form.repeatCall" placeholder="请选择"
						style="width: 120px;">
						<el-option label="0" value="0"></el-option>
						<el-option label="1" value="1"></el-option>
						<el-option label="2" value="2"></el-option>
						<el-option label="3" value="3"></el-option>
					</el-select>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span style="font-size: 12px;color: #878A93;">0为不限制企业同号码外呼次数。其他选项 如1，则限制该企业同号码外呼次数为1次。</span>
				</el-form-item>
				<el-form-item label="共享黑名单" prop="blacklist" v-if="repeatCallgew">
					<el-switch v-model="form.blacklist" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span
						style="font-size: 12px;color: #878A93;">开启后该企业会共享{{ webInfo.webName }}所有黑名单号码，如果命中则拦截无法拨打。</span>
				</el-form-item>
				<el-form-item label="共享风险号码" prop="riskNumber" v-if="repeatCallgew">
					<el-switch v-model="form.riskNumber" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
					<span style="color: #ff0000;margin: 6px 6px 0 20px;">*</span>
					<span
						style="font-size: 12px;color: #878A93;">开启后该企业会共享{{ webInfo.webName }}当天所有风险号码，如果命中则拦截无法拨打。</span>
				</el-form-item>
				<el-form-item label="每日语音质检" prop="repeatCall" v-if="isAgent || isAdmin">
					<el-select size="small" :disabled="canRemoveCompany" v-model="form.aiQuality" placeholder="请选择"
						style="width: 220px;">
						<el-option label="不质检" value="0"></el-option>
						<el-option label="轻度质检(5条)" value="5"></el-option>
						<el-option label="中度质检(10条)" value="10"></el-option>
						<el-option label="高度质检(20条)" value="20"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
				<el-button size="small" @click="closeDialog">取消</el-button>
				<el-button size="small" v-if="addButton" :loading="loading1" type="primary" :class="[theme]"
					@click="addCompany('form')">确定</el-button>
				<el-button size="small" v-if="updateButton" :loading="loading2" type="primary" :class="[theme]"
					@click="updateCompany('form')">确定</el-button>
			</span>
		</el-dialog>

		<el-dialog v-model="isrepeatBox" width="450px" :show-close="false" :close-on-click-modal="false">
			<div>
				<div style="text-align: center;">
					<i class="el-icon-warning-outline" style="color: #F56C6C;font-size: 76px;margin-bottom: 35px;"></i>
					<div style="margin-left: 20px;text-align: left;font-size: 16px;">
						关闭去重功能，会出现同一个号码重复分配给不同员工，极有可能出现撞单和频繁骚扰情况，请谨慎使用！
					</div>
				</div>
				<div style="display: flex;flex-direction: row;justify-content: end;margin-top: 50px;">
					<el-button size="small" @click="quchong">去重</el-button>
					<el-button size="small" type="danger" @click="buquchong">不去重</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list,
		delCompany,
		addCompany,
		updateCompany,
		fetchCompanyById,
		fetchCompanyByAgentId,
		uptoken,
		uptoken1,
		fetchDefaultCompanies,
		updateEnable,
		fetchCompanyType,
		downloadCompany
	} from '../api/companyIndex.js';
	import { fetchAgent } from '../api/agentIndex';
	import { getOemInfo } from '../api/index.js';
	import { querySubAgent } from '../api/serialIndex.js';
	const video = document.createElement('video');

	export default {
		name: 'company',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				uploadDisabled: false,
				repeatText: '号码去重',
				logoId: '1', //专区logo id
				dialogVisible: false,
				ruleForm: {
					dialogImageUrl: '1' //专区logo 上传到后台之后，后台会返回一个id,只需要给后台传id,但是点击编辑的时候后台返回的是http地址
				},
				query: {
					companyId: '',
					businessType: '',
					enable: '',
					companyName: '',
					pageIndex: 1,
					pageSize: 30,
					subAgentId: ''
				},
				businessTypeList: [],
				domain: 'https://upload-z2.qiniup.com',
				qiniuaddr: ' r8s8zg6k5.hn-bkt.clouddn.com',
				token1: {},
				config: null,
				keyname: '',
				formdata: '',
				imageUrl: '',
				detailsVisible: false,
				tableData2: [],
				isSubAgent: false,
				dialogImageUrl1: null,
				dialogImageUrl2: null,
				dialogImageUrl3: null,
				dialogImageUrl4: null,
				dialogImageUrl5: null,
				dialogImageUrl6: null,
				dialogImageUrl7: null,
				dialogImageUrl8: null,
				dialogImageUrl9: null,
				addVisible1: false,
				addVisible2: false,
				addVisible3: false,
				addVisible4: false,
				addVisible5: false,
				addVisible6: false,
				addVisible7: false,
				addVisible8: false,
				addVisible9: false,
				hideUpload1: false,
				hideUpload2: false,
				hideUpload3: false,
				hideUpload4: false,
				hideUpload5: false,
				hideUpload6: false,
				hideUpload7: false,
				hideUpload8: false,
				hideUpload9: false,
				webInfo: '',
				limitCount: 1,
				fileList1: [],
				fileList2: [],
				fileList3: [],
				fileList4: [],
				fileList5: [],
				fileList6: [],
				fileList7: [],
				fileList8: [],
				fileList9: [],
				coverUrl: '',
				tableData: [],
				companys: [],
				multipleSelection: [],
				delList: [],
				agents: [],
				editVisible: false,
				addVisible: false,
				addButton: false,
				updateButton: false,
				canAddCompany: true,
				canEditCompany: false,
				protectionLevelTrue: true,
				canRemoveCompany: true,
				isAdmin: false,
				isagent: false,
				pageTotal: 0,
				form: {
					name: '',
					code: '',
					businessType: '',
					businessLicense: null,
					idcardFront: null,
					idcardReverse: null,
					legalPersonPhotos: null,
					template1: null,
					template2: null,
					template3: null,
					template4: null,
					template5: null,
					ownerName: '',
					ownerPhone: '',
					protectionLevel: '',
					desensitization: false,
					repeat: true, //是否去重
					export: false, //是否导出
					repeatCall: 1, //是否重复拨打
					blacklist: false, //共享黑名单
					riskNumber: false, // 共享风险号码
					frequency: '',
					highFrequency: false, //高频开启
					applet: true,
					department: true,
					aiQuality: '轻度质检(5条)'
				},
				repeatCall: 1, //是否重复拨打
				repeatCallgew: false, //是否显示重复拨打
				idx: -1,
				id: -1,
				loading: false,
				loading1: false,
				loading2: false,
				isrepeatBox: false,
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },

				rules: {
					name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
					code: [{ required: true, message: '请输入企业代码', trigger: 'blur' }],
					// businessLicense: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
					// idcardFront: [{ required: true, message: '请上传身份证正面', trigger: 'blur' }],
					// idcardReverse: [{ required: true, message: '请上传身份证反面', trigger: 'blur' }],
					// legalPersonPhotos: [{ required: true, message: '请上传法人免冠照', trigger: 'blur' }],
					ownerName: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
					ownerPhone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
					protectionLevel: [{ required: true, message: '请选择业务类型', trigger: 'blur' }],
					agentId: [{ required: true, message: '请输入代理商', trigger: 'blur' }]
				},
				protectionLevel: [{
						value: '',
						label: '禁用',
						children: [{
							value: '500',
							label: '禁用电话邦'
						}]
					},
					{
						value: '',
						label: '预判大模型',
						children: [{
								value: '24',
								label: '模型一'
							},
							{
								value: '25',
								label: '模型二'
							},
							{
								value: '26',
								label: '模型三'
							},
							{
								value: '27',
								label: '模型四'
							},
							{
								value: '28',
								label: '模型五'
							}
						]
					},
					{
						value: '',
						label: '催收',
						children: [{
								value: '2',
								label: '催本人'
							},
							{
								value: '32',
								label: '催紧急联系人'
							},
							{
								value: '33',
								label: '催三方'
							}
						]
					},
					{
						value: '',
						label: '金融',
						children: [{
								value: '4',
								label: '贷款（个人）'
							},
							{
								value: '11',
								label: 'hy2'
							},
							{
								value: '7',
								label: 'hy1'
							},
							{
								value: '23',
								label: '信用卡'
							},
							{
								value: '16',
								label: 'POS 机 '
							},
							{
								value: '9',
								label: '理财产品'
							},
							{
								value: '6',
								label: '保险'
							}
						]
					},
					{
						value: '',
						label: '企业服务',
						children: [{
								value: '31',
								label: '贷款'
							},
							{
								value: '30',
								label: '财税、印花税 '
							},
							{
								value: '29',
								label: '法律咨询 '
							}
						]
					},

					{
						value: '',
						label: '游戏',
						children: [{
								value: '17',
								label: '通知'
							},
							{
								value: '34',
								label: '人工'
							},
							{
								value: '35',
								label: 'AI'
							}
						]
					},
					{
						value: '',
						label: '会员营销（回访）',
						children: [{
								value: '36',
								label: '纯回访，无营销性质'
							},
							{
								value: '37',
								label: '浏览过回访营销'
							},
							{
								value: '38',
								label: '注册会员营销'
							}
						]
					},
					{
						value: '',
						label: '通知/验证',
						children: [{
								value: '21',
								label: '通知'
							},
							{
								value: '1',
								label: '验证'
							},
							{
								value: '22',
								label: '问卷调查'
							},
							{
								value: '3',
								label: '语音验证码'
							}
						]
					},
					{
						value: '',
						label: '房产',
						children: [{
								value: '5',
								label: '新房'
							},
							{
								value: '18',
								label: '二手房'
							}
						]
					},
					{
						value: '',
						label: '食药、保健品',
						children: [{
								value: '12',
								label: '精准+复购'
							},
							{
								value: '15',
								label: '盲呼 '
							}
						]
					},
					{
						value: '',
						label: '医美、护肤瘦身、美容美发',
						children: [{
								value: '39',
								label: '精准+复购'
							},
							{
								value: '40',
								label: '盲呼 '
							}
						]
					},
					{
						value: '',
						label: '医疗器械',
						children: [{
								value: '41',
								label: '精准+复购'
							},
							{
								value: '42',
								label: '盲呼 '
							}
						]
					},
					{
						value: '',
						label: '婚恋、交友',
						children: [{
								value: '43',
								label: '精准+复购'
							},
							{
								value: '44',
								label: '盲呼 '
							}
						]
					},
					{
						value: '',
						label: '酒水、烟草 ',
						children: [{
							value: '45',
							label: '精准+复购'
						}]
					},
					{
						value: '',
						label: '汽车',
						children: [{
								value: '46',
								label: '新车'
							},
							{
								value: '47',
								label: '二手车'
							}
						]
					},
					{
						value: '',
						label: '装修',
						children: [{
								value: '48',
								label: '家装'
							},
							{
								value: '49',
								label: '家居建材'
							},
							{
								value: '20',
								label: '电器邀约'
							}
						]
					},
					{
						value: '',
						label: '电商',
						children: [{
								value: '50',
								label: '淘宝商家'
							},
							{
								value: '51',
								label: '家政服务'
							}
						]
					},
					{
						value: '',
						label: '教育培训',
						children: [{
								value: '52',
								label: '成人教育'
							},
							{
								value: '8',
								label: '学生类业务'
							},
							{
								value: '53',
								label: '其他培训'
							}
						]
					},
					{
						value: '',
						label: '招商会议',
						children: [{
								value: '19',
								label: '本地线打本地'
							},
							{
								value: '54',
								label: '升级套餐业务'
							}
						]
					},
					{
						value: '',
						label: '招聘类',
						children: [{
							value: '10',
							label: '招聘类'
						}]
					},
					{
						value: '',
						label: '旅游类',
						children: [{
							value: '13',
							label: '旅游类'
						}]
					},
					{
						value: '',
						label: '网购类',
						children: [{
							value: '14',
							label: '网购类'
						}]
					}
				],

				frequencyList: [{
						value: '',
						label: '三天',
						children: [{
								value: '3',
								label: '3次'
							},
							{
								value: '4',
								label: '4次'
							},
							{
								value: '5',
								label: '5次'
							}
						]
					},
					{
						value: '',
						label: '四天',
						children: [{
								value: '3',
								label: '3次'
							},
							{
								value: '4',
								label: '4次'
							},
							{
								value: '5',
								label: '5次'
							}
						]
					},
					{
						value: '',
						label: '五天',
						children: [{
								value: '3',
								label: '3次'
							},
							{
								value: '4',
								label: '4次'
							},
							{
								value: '5',
								label: '5次'
							}
						]
					}
				],

				coverUrl: ''
			};
		},

		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'normal') {
				this.canAddCompany = false;
				this.canEditCompany = true;
				this.canRemoveCompany = true;
			}
			if (role === 'company') {
				this.canAddCompany = false;
				this.protectionLevelTrue = true;
				this.canRemoveCompany = true;
			}

			if (role === 'agent') {
				this.isNotAdmin = false;
				this.isAgent = true;
				this.isagent = true;
				this.canRemoveCompany = false;
				this.protectionLevelTrue = false;
				this.repeatCallgew = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'admin') {
				this.isAgent = true;
				this.isAdmin = true;
				this.canRemoveCompany = false;
				this.protectionLevelTrue = false;
				this.repeatCallgew = true;
			}

			this.init();
			this.fetchCompanyType();
			this.getOEMagentOem();
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
					if (res.data.length > 0) {
						this.query.companyId = this.companys[0].value;
						this.query.pageIndex = 1;
						this.getData();
					}
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getOEMagentOem() {
				// let url = 'crm.jilinlingshang.com';
				let url = window.location.host;
				getOemInfo(url)
					.then(res => {
						if (res.code == 200) {
							this.webInfo = res.data;
							let linkicon = document.querySelector("link[rel*='icon']") || document.createElement(
								'link');
							linkicon.href = res.data.icon;
							localStorage.setItem('webName', res.data.webName);
						} else {
							this.$message.error('查询失败，请稍后再试！');
						}
					})
					.catch(err => {
						this.$message.error('查询失败，请稍后再试！');
					});
			},
			httprequest(req) {
				this.config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				};
				this.keyname = 'lytton' + new Date() + Math.floor(Math.random() * 100) + '.';
				uptoken().then(res => {
					this.token1 = res;
					this.formdata = new FormData();
					this.formdata.append('file', req.file);
					this.formdata.append('toke', req.data);
					this.formdata.append('key', this.keyname);
					uptoken1(this.domain, this.formdata, this.config).then(res => {
						this.imageUrl = 'http://' + this.qiniuaddr + '/' + res.data.key;
					});
				});
			},
			fetchCompanyType() {
				fetchCompanyType().then(res => {
					if (res.code == 200) {
						this.businessTypeList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 删除照片
			handleRemove1() {
				console.log('handleRemove1');
				this.form.businessLicense = '';
			},
			handleRemove2() {
				this.form.idcardFront = '';
			},
			handleRemove3() {
				this.form.idcardReverse = '';
			},
			handleRemove4() {
				this.form.legalPersonPhotos = '';
			},
			handleRemove5() {
				this.form.template1 = '';
				console.log('话术磨板1', this.form.template1);
			},
			handleRemove6() {
				this.form.template2 = '';
				console.log('话术磨板2', this.form.template2);
			},
			handleRemove7() {
				this.form.template3 = '';
			},
			handleRemove8() {
				this.form.template4 = '';
			},
			handleRemove9() {
				this.form.template5 = '';
			},
			//查看大图
			handlePictureCardPreview1(file) {
				this.dialogImageUrl1 = file.url;
				this.addVisible1 = true;
			},
			handlePictureCardPreview2(file) {
				this.dialogImageUrl2 = file.url;
				this.addVisible2 = true;
			},
			handlePictureCardPreview3(file) {
				this.dialogImageUrl3 = file.url;
				this.addVisible3 = true;
			},
			handlePictureCardPreview4(file) {
				this.dialogImageUrl4 = file.url;
				this.addVisible4 = true;
			},
			handlePictureCardPreview5(file) {
				this.dialogImageUrl5 = file.url;
				this.addVisible5 = true;
			},
			handlePictureCardPreview6(file) {
				this.dialogImageUrl6 = file.url;
				this.addVisible6 = true;
			},
			handlePictureCardPreview7(file) {
				this.dialogImageUrl7 = file.url;
				this.addVisible7 = true;
			},
			handlePictureCardPreview8(file) {
				this.dialogImageUrl8 = file.url;
				this.addVisible8 = true;
			},
			handlePictureCardPreview9(file) {
				this.dialogImageUrl9 = file.url;
				this.addVisible9 = true;
			},

			handleUploadSuccess(response, file, fileList) {
				//为 el-upload on-success 方法实现
				this.fileList = fileList;
				if (response.code != 0) {
					this.$message({
						message: '附件上传失败',
						type: 'error'
					});
					this.fileList.splice(this.fileList.indexOf(file, 1));
				} else {
					let _fileName = file.name;
					if (this.$utils.getFileIsVideo(_fileName)) {
						//getFileIsVideo 为我本地自定义判断是否是视频方法，可自己修改
						//视频附件，获取第一帧画面作为 封面展示
						this.getVideoCover(file);
					}
				}
			},

			// 上传照片后隐藏上传按钮
			beformRemove1(fileList) {
				this.hideUpload1 = fileList.length >= this.limitCount;
			},
			beformRemove2(fileList) {
				this.hideUpload2 = fileList.length >= this.limitCount;
			},
			beformRemove3(fileList) {
				this.hideUpload3 = fileList.length >= this.limitCount;
			},
			beformRemove4(fileList) {
				this.hideUpload4 = fileList.length >= this.limitCount;
			},
			beformRemove5(fileList) {
				this.hideUpload5 = fileList.length >= this.limitCount;
			},
			beformRemove6(fileList) {
				this.hideUpload6 = fileList.length >= this.limitCount;
			},
			beformRemove7(fileList) {
				this.hideUpload7 = fileList.length >= this.limitCount;
			},
			beformRemove8(fileList) {
				this.hideUpload8 = fileList.length >= this.limitCount;
			},
			beformRemove9(fileList) {
				this.hideUpload9 = fileList.length >= this.limitCount;
			},

			// 传营业执照
			getbusinessLicense(file, fileList) {
				this.hideUpload1 = fileList.length >= this.limitCount;
				this.getBase64(file.raw).then(res => {
					this.form.businessLicense = res;
				});
			},
			// 传身份证正面
			getIDCardFront(file, fileList) {
				this.hideUpload2 = fileList.length >= this.limitCount;
				this.getBase64(file.raw).then(res => {
					this.form.idcardFront = res;
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			// 传身份证反面
			getIDCardReverse(file, fileList) {
				this.hideUpload3 = fileList.length >= this.limitCount;

				this.getBase64(file.raw).then(res => {
					this.form.idcardReverse = res;
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			// 传法人照片
			getlegalPersonPhotos(file, fileList) {
				this.hideUpload4 = fileList.length >= this.limitCount;

				this.getBase64(file.raw).then(res => {
					this.form.legalPersonPhotos = res;
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			// 传话术磨板1
			gettemplate1(file, fileList) {
				this.hideUpload5 = fileList.length >= this.limitCount;
				this.getBase64(file.raw).then(res => {
					this.form.template1 = res;
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			// 传话术磨板2
			gettemplate2(file, fileList) {
				this.hideUpload6 = fileList.length >= this.limitCount;
				this.getBase64(file.raw).then(res => {
					this.form.template2 = res;
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			// 传话术磨板3
			gettemplate3(file, fileList) {
				this.hideUpload7 = fileList.length >= this.limitCount;
				this.getBase64(file.raw).then(res => {
					this.form.template3 = res;
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			// 传话术磨板4
			gettemplate4(file, fileList) {
				this.hideUpload8 = fileList.length >= this.limitCount;
				this.getBase64(file.raw).then(res => {
					this.form.template4 = res;
					console.log(res, 'ssss');
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			// 传话术磨板5
			gettemplate5(file, fileList) {
				this.hideUpload9 = fileList.length >= this.limitCount;
				// this.videoURL = fileList.url;
				// this.captureCover();
				this.getBase64(file.raw).then(res => {
					this.form.template5 = res;
					const params = res.split(',');
					if (params.length > 0) {
						this.strimagebase64 = params[1];
					}
				});
			},
			captureCover() {
				video.crossOrigin = 'Anonymous'; // 允许跨域访问视频资源
				video.preload = 'metadata';
				video.src = this.videoURL;
				video.onloadedmetadata = () => {
					video.currentTime = 0.1; // 设置到视频的某个时间点以获取封面，这里选择0.1秒
				};

				video.onseeked = () => {
					const canvas = document.createElement('canvas');
					canvas.width = video.videoWidth;
					canvas.height = video.videoHeight;
					const ctx = canvas.getContext('2d');
					ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
					// this.fileList9[0].url = canvas.toDataURL('image/png'); // 将封面转换为DataURL
				};
			},

			closeDialogdialogImageUrl9() {
				this.addVisible9 = false;
			},
			// 转图片转vase64
			getBase64(file) {
				return new Promise(function(resolve, reject) {
					let reader = new FileReader();
					let imgResult = '';
					reader.readAsDataURL(file);
					reader.onload = function() {
						imgResult = reader.result;
					};
					reader.onerror = function(error) {
						reject(error);
					};
					reader.onloadend = function() {
						resolve(imgResult);
					};
				});
			},

			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},

			getData() {
				this.query.currentUserId = localStorage.getItem('user');
				list(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
						// this.agentChange(this.query.agentId);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				this.addVisible = true;
				this.form.repeat = true;
				this.form.applet = true;
				this.addButton = true;
				this.updateButton = false;
			},

			// 点击确定上传信息
			addCompany(formName) {
				// this.loading1 = true;
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading1 = true;
						this.form.currentUserId = localStorage.getItem('user');

						if (this.form.aiQuality == '不质检') {
							this.form.aiQuality = 0;
						} else if (this.form.aiQuality == '轻度质检(5条)') {
							this.form.aiQuality = 5;
						} else if (this.form.aiQuality == '中度质检(10条)') {
							this.form.aiQuality = 10;
						} else if (this.form.aiQuality == '高度质检(20条)') {
							this.form.aiQuality = 20;
						}
						addCompany(this.form).then(res => {
							if (res.code == 200) {
								this.$message.success('添加成功');
								this.loading1 = false;
								this.$refs.upload1.clearFiles();
								this.$refs.upload2.clearFiles();
								this.$refs.upload3.clearFiles();
								this.$refs.upload4.clearFiles();
								this.$refs.upload5.clearFiles();
								this.$refs.upload6.clearFiles();
								this.$refs.upload7.clearFiles();
								this.$refs.upload8.clearFiles();
								this.$refs.upload9.clearFiles();
								this.getData();
							} else {
								this.$message.error(res.message);
							}
							this.loading1 = false;
							this.addVisible = false;
							// window.location.reload();
						});
					}
				});
			},

			// 编辑操作
			handleEdit(index, id) {
				this.addVisible = true;
				this.idx = index;
				this.addButton = false;
				this.updateButton = true;
				// 获取详情
				if (!index && !id) return;
				fetchCompanyById(id).then(res => {
					if (res.code == 200) {
						if (res.data.businessLicense) {
							this.fileList1 = [{ url: 'http://' + res.data.businessLicense }];
							this.hideUpload1 = this.fileList1.length >= this.limitCount;
						}
						if (res.data.idcardFront) {
							this.fileList2 = [{ url: 'http://' + res.data.idcardFront }];
							this.hideUpload2 = this.fileList2.length >= this.limitCount;
						}
						if (res.data.idcardReverse) {
							this.fileList3 = [{ url: 'http://' + res.data.idcardReverse }];
							this.hideUpload3 = this.fileList3.length >= this.limitCount;
						}
						if (res.data.legalPersonPhotos) {
							this.fileList4 = [{ url: 'http://' + res.data.legalPersonPhotos }];
							this.hideUpload4 = this.fileList4.length >= this.limitCount;
						}
						if (res.data.template1) {
							this.fileList5 = [{ url: 'http://' + res.data.template1 }];
							this.hideUpload5 = this.fileList5.length >= this.limitCount;
						}
						if (res.data.template2) {
							this.fileList6 = [{ url: 'http://' + res.data.template2 }];
							this.hideUpload6 = this.fileList6.length >= this.limitCount;
						}
						if (res.data.template3) {
							this.fileList7 = [{ url: 'http://' + res.data.template3 }];
							this.hideUpload7 = this.fileList7.length >= this.limitCount;
						}
						if (res.data.template4) {
							this.fileList8 = [{ url: 'http://' + res.data.template4 }];
							this.hideUpload8 = this.fileList8.length >= this.limitCount;
						}
						if (res.data.template5) {
							this.fileList9 = [{ url: 'http://' + res.data.template5 }];
							this.hideUpload9 = this.fileList9.length >= this.limitCount;
							// this.videoURL = this.fileList9[0].url;
							// this.captureCover();
						}
						this.form = res.data;
						if (this.form.aiQuality == 0) {
							this.form.aiQuality = '不质检';
						} else if (this.form.aiQuality == 5) {
							this.form.aiQuality = '轻度质检(5条)';
						} else if (this.form.aiQuality == 10) {
							this.form.aiQuality = '中度质检(10条)';
						} else if (this.form.aiQuality == 20) {
							this.form.aiQuality = '高度质检(20条)';
						}
						this.form.protectionLevel = String(this.form.protectionLevel);
						if (this.form.repeat == true) {
							this.repeatText = '号码去重';
						} else {
							this.repeatText = '号码不去重';
						}
						if (this.form.applet == null) {
							this.form.applet = true;
						}
					} else {
						this.$message.error('获取失败');
					}
				});
			},
			// 点击修改信息
			updateCompany(formName) {
				// this.loading2 = true;
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading2 = true;
						console.log(this.form, 'ddddd');
						if (this.form.aiQuality == '不质检') {
							this.form.aiQuality = 0;
						} else if (this.form.aiQuality == '轻度质检(5条)') {
							this.form.aiQuality = 5;
						} else if (this.form.aiQuality == '中度质检(10条)') {
							this.form.aiQuality = 10;
						} else if (this.form.aiQuality == '高度质检(20条)') {
							this.form.aiQuality = 20;
						}
						updateCompany(this.form).then(res => {
							if (res.code == 200) {
								this.$message.success('修改成功');
								this.getData();
								this.loading2 = false;
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
							this.loading2 = false;
						});
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						this.$confirm('删除后数据将无法修复!!!', '提示', {
								type: 'warning'
							})
							.then(() => {
								let data = {
									id: id,
									userId: localStorage.getItem('user')
								};
								delCompany(data).then(res => {
									if (res.code == 200) {
										this.$message.success('删除成功');
										this.pageTotal = this.pageTotal - 1;
										this.tableData.splice(index, 1);
									} else {
										this.$message.error(res.message);
									}
								});
							})
							.catch(() => {});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 点击取消
			closeDialog(formName) {
				this.$refs.upload1.clearFiles();
				this.$refs.upload2.clearFiles();
				this.$refs.upload3.clearFiles();
				this.$refs.upload4.clearFiles();
				this.$refs.upload5.clearFiles();
				this.$refs.upload6.clearFiles();
				this.$refs.upload7.clearFiles();
				this.$refs.upload8.clearFiles();
				this.$refs.upload9.clearFiles();
				this.hideUpload1 = false;
				this.hideUpload2 = false;
				this.hideUpload3 = false;
				this.hideUpload4 = false;
				this.hideUpload5 = false;
				this.hideUpload6 = false;
				this.hideUpload7 = false;
				this.hideUpload8 = false;
				this.hideUpload9 = false;
				this.addVisible = false;
				(this.form = {
					name: '',
					code: '',
					ownerName: '',
					ownerPhone: '',
					businessLicense: '',
					idcardFront: '',
					idcardReverse: '',
					legalPersonPhotos: '',
					template1: '',
					template2: '',
					template3: '',
					template4: '',
					template5: ''
				}),
				this.resetForm(formName);
			},
			handleChange(value) {
				this.form.protectionLevel = value[1];
			},
			frequencyhandleChange(value) {
				this.form.frequencyList = value[1];
			},
			getswitch3(e) {
				if (e == true) {
					console.log('部门department', e);
				} else {
					console.log('部门department', e);
				}
			},
			getswitch4(e) {
				if (e == true) {
					console.log('号码保护desensitization', e);
				} else {
					console.log('号码保护desensitization', e);
				}
			},
			getswitch(e) {
				if (e == true) {
					this.isrepeatBox = false;
					this.repeatText = '号码去重';
				} else {
					this.isrepeatBox = true;
					this.repeatText = '号码不去重';
				}
			},
			getappletswitch(e) {
				this.form.applet = e;
			},
			getswitch2(e) {
				if (e == true) {
					console.log(this.form.export, 'truetruetrue');
					this.form.export = false;
				} else {
					console.log(e, 'falsefalsefalse');
					this.form.export = true;
				}
			},
			getswitchenable(row) {
				let data = {
					companyId: row.id,
					enable: row.enable
				};
				updateEnable(data).then(res => {
					if (res.code == 200) {
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			quchong() {
				this.repeatText = '号码去重';
				this.isrepeatBox = false;
				this.form.repeat = true;
			},
			buquchong() {
				this.isrepeatBox = false;
				this.repeatText = '号码不去重';
				this.form.repeat = false;
			},
			handleExport() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					enable: this.query.enable
				};
				downloadCompany(data).then(res => {
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '企业' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.el-form-item__label {
		font-size: 13px !important;
	}

	.content_top {
		display: flex;
		justify-content: space-around;
	}

	.content_bottom {
		display: flex;
		justify-content: space-around;
	}

	.content_middle {
		display: flex;
		margin: 0.625rem 0 0.625rem 0;
		/* margin-bottom: 0.625rem; */
		justify-content: space-around;
	}

	.ywlx:hover {
		color: #00a854;
		background: none;
	}

	.content_top {
		display: flex;
		margin: auto;
	}
</style>